import './htmx';
import 'htmx-ext-head-support/head-support';

import Alpine from 'alpinejs';
import Toastify from 'toastify-js';

window.Alpine = Alpine;
window.Toastify = Toastify;

Alpine.start();
